<template>
  <div class="about">
    <img v-for="(item,index) in pageAd" :key="index" :src="serverUrl + item.savePath" alt="">
    <div class="tabList">
      <div class="tabs-wrap">
        <div class="tabs">
          <div class="item" :class="tabIndex === index ? 'active' : ''" v-for="(item,index) in tabs" :key="index"
               @click="selectTabItem(index)">{{ item }}
          </div>
        </div>
      </div>
      <div class="tabs-content">
        <div class="first-tab" v-if="tabIndex === 0 && companyProfile">
          <p class="title">{{ companyProfile.title }}</p>
          <div class="content" v-html="companyProfile.content"></div>
        </div>

        <div class="secend-tab" v-if="tabIndex === 1 && companyCulture">
          <p class="title">{{ companyCulture.title }}</p>
          <div class="content" v-html="companyCulture.content"></div>
        </div>

        <div class="third-tab" v-if="tabIndex === 2">
          <p class="title">企业资质</p>
          <div class="enterprise">
            <div class="enterprise-item" v-for="(item,index) in qualification" :key="index" @click="toMore(item)">
              <img :src="serverUrl + item.coverImage" alt="">
              <div class="text">{{item.title}}</div>
            </div>
<!--            <div class="enterprise-item">-->
<!--              <img src="../../assets/image/enterprise.png" alt="">-->
<!--              <div class="text">营业执照</div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAd, getArtfir, getArts} from "@/api/api";
import {serverUrl} from "@/utils/config";
export default {

  data() {
    return {
      serverUrl: serverUrl,
      activeName: "first",
      tabs: ["公司简介", "企业文化", "企业资质"],
      tabIndex: 0,
      companyProfile: null, // 公司简介
      companyCulture: null, // 企业文化
      qualification: null,  // 企业资质
      pageAd: []
    };
  },
  activated() {
    this.getData();
    this.getPageAd();
    let type = this.$route.query.type;
    if(type !== undefined){
      this.tabIndex = Number(type);
    }
  },
  methods: {
    getPageAd(){
      getAd({
        code: 'about_ad'
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageAd = data;
        }
      })
    },
    getData() {
      getArtfir({
        categoryId: 2
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.companyProfile = data;
        }
      })
      getArtfir({
        categoryId: 7
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.companyCulture = data;
        }
      })
      getArts({
        categoryId: 8,
        pagination: false,
        containsContent: true
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.qualification = data.rows;
        }
      })
    },
    selectTabItem(tabIndex) {
      this.tabIndex = tabIndex;
    },
    toMore(data) {
      this.$router.push({
        path: '/new_detail', query: {
          id: data.id,
          backPage: 'about',
          backTitle: '关于我们',
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
